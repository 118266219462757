import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchShow } from '../rtk/slices/show-slice'

function Advantage () {
    const dispatch = useDispatch();
    const show = useSelector((state) => state.show.show);

    useEffect(() => {
        // fetch show
        dispatch(fetchShow());
    }, [])

    return (
        <section className='certificates' id='services'>
            <div className="container">
                <h2 data-aos="fade-left" className="title">
                    <span>مميزاتنا</span>
                    <p>مميزاتنا</p>
                </h2>
                <div className="cards">
                    {show.map((card, index) => {
                        return (
                            <div to={`/services/service/${card.keyword}`} style={{ flexDirection: "column", padding: "0 .5rem" }} data-aos="zoom-out-up" className="card" key={card.id}>
                                <h1 style={{ color: "gray", fontSize: "3rem", opacity: ".5" }}>0{index + 1}</h1>
                                <h4>{card.keyword}</h4>
                                <p style={{ textAlign: "center", opacity: ".5" }}>{card.description}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
export default Advantage
