import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchServices } from '../rtk/slices/services-slice';

function Service () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { id } = useParams();

    setTimeout(() => {
        const support = document.querySelector('.support-menu');
        const social = document.querySelector('.contact-menu .main');
        if (support) {
            support.addEventListener("click", () => {
                social.classList.toggle("active")
            })
        }
    }, 2000)

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };

    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);
    const color = useSelector((state) => state.color.color);

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchServices());

    }, [])

    return (
        <section className='projects service' id='projects'>
            <div className="contact-menu">
                <div className="main">
                    <div className="up">
                        <a href={"tel:0500861924"} className="card1">
                            <i className="fa fa-phone instagram"></i>
                        </a>

                    </div>
                    <div className="down">
                        <a href={"https://api.whatsapp.com/send/?phone=0500861924"} target='_blank' className="card3">
                            <i className="fa fa-whatsapp whatsapp"></i>
                        </a>
                    </div>
                </div>
                <button target='_blank' className="card4 support-menu">
                    <i className="fa fa-phone support"></i>
                </button>
            </div>

            <div className="container">
                <h2 className="title">
                    <span>الخدمات</span>
                    <p style={{ textTransform: "capitalize" }}>الخدمات</p>
                </h2>

                {services.map((service) => {
                    return (
                        service['id'] == id ?
                            <div className="card-service" key={service.id}>
                                <div className="img">
                                    <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img_service}`} alt="" />
                                </div>
                                <div className="content">
                                    <div className="box">
                                        <h3> {service.keyword}</h3>
                                        <p>
                                            {service.whats_service.split(',').map((what) => {
                                                return (
                                                    <>
                                                        <span>{what}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    <div className="box">
                                        <h3>خدماتنا التي نتميز بها</h3>
                                        <p className='priceP'>
                                            {service.prices_service.split(',').map((price) => {
                                                return (
                                                    <>
                                                        <span className='priceSpan'>{price}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                    <a href='tel:0500861924' style={{ textAlign: "center" }} class="btns"><button class="button type1"><span class="btn-txt"><a href="tel:0500861924">إتصل بنا</a></span></button></a>
                                </div>
                            </div>
                            : null
                    )
                })}


                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Service
