import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import show1 from '../assest/des1.jpg'
import show2 from '../assest/des3.jpg'
import show3 from '../assest/des2.jpg'
import show4 from '../assest/des4.jpg'
import show5 from '../assest/des5.jpg'
import show6 from '../assest/des6.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchShow } from '../rtk/slices/show-slice'
import { fetchServices } from '../rtk/slices/services-slice'

function Certificates () {
    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);

    useEffect(() => {
        // fetch show
        dispatch(fetchServices());
    }, [])

    return (
        <section className='certificates' id='services'>
            <div className="container">
                <h2 data-aos="fade-left" className="title">
                    <span>الخدمات</span>
                    <p>الخدمات</p>
                </h2>
                <div className="cards">
                    {services.map((card) => {
                        return (
                            <Link to={`/service/${card.id}`} style={{ flexDirection: "column", padding: "0 .5rem" }} data-aos="zoom-out-up" className="card" key={card.id}>
                                <div><img style={{ width: "100%", maxWidth: "12rem", maxHeight: "10rem" }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${card.img_service}`} alt="" /></div>
                                <h4>{card.keyword}</h4>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Certificates
