import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import All from "./components/All";
import Service from "./components/Service";
import Project_Page from "./components/Project_Page";

function App () {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<All />} />
          <Route path="/service/:id" element={<Service />} />
          <Route path="/project/:id" element={<Project_Page />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
