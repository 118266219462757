import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import show1 from '../assest/des1.jpg'
import show2 from '../assest/des3.jpg'
import show3 from '../assest/des2.jpg'
import show4 from '../assest/des4.jpg'
import show5 from '../assest/des5.jpg'
import show6 from '../assest/des6.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProjects } from '../rtk/slices/projects-slice'
import ReactPlayer from 'react-player'

function Projects () {
    const dispatch = useDispatch();
    const showProjects = useSelector((state) => state.projects.projects);

    useEffect(() => {
        // fetch show
        dispatch(fetchProjects());
    }, [])

    // const limitBlogs = showProjects.map((card, index) => {
    //     return index < 3 ? <>
    //         <Link to={`/blogs/blog/${card.id}`} data-aos="zoom-out-up" className="card" key={card.id}>
    //             <div style={{ textAlign: "center" }}>
    //                 <img style={{ width: "100%", maxWidth: "19rem", borderRadius: ".5rem" }} src={`${process.env.REACT_APP_PROJECTS_API}admin/imgs/${card.image}`} alt="" />
    //             </div>
    //             <h4><span style={{ color: "var(--main-color-opacity-100)" }}>التصنيف:- </span>{card.title}</h4>
    //             <p>{card.description.substring(0, 100)}..</p>
    //         </Link>
    //     </> : null
    // })

    return (
        <section className='memories projects blogs' id='projects'>

            <div className="container">
                <h2 data-aos="fade-left" className="title">
                    <span>المشاريع</span>
                    <p>المشاريع</p>
                </h2>
                <div className="cards">
                    {showProjects.map((card, index) => {
                        return index < 3 ? <>
                            <Link to={`/project/${card.id}`} data-aos="zoom-out-up" className="card" key={card.id}>
                                <div style={{ textAlign: "center" }}>
                                    <img style={{ width: "100%", maxWidth: "19rem", borderRadius: ".5rem", maxHeight: "13rem" }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${card.img}`} alt="" />
                                </div>
                                <h4><span style={{ color: "var(--main-color-opacity-100)" }}></span>{card.name}</h4>
                                {/* <p>{card.description.substring(0, 100)}..</p> */}
                            </Link>
                        </> : null
                    })}
                </div>
            </div>
        </section>
    )
}

export default Projects
