import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';


// import required modules
import { Autoplay } from 'swiper/modules';

import swiper1 from '../assest/about1.png'
import swiper3 from '../assest/about3.png'
import swiper4 from '../assest/about4.png'
import swiper5 from '../assest/about5.png'
import swiper6 from '../assest/about6.png'
import swiper7 from '../assest/about7.png'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAbout } from '../rtk/slices/about-slice';

function About () {
    const dispatch = useDispatch();
    const about = useSelector((state) => state.about.about);

    useEffect(() => {
        // fetch about
        dispatch(fetchAbout());
    }, [])
    return (
        <section className='about' id='about'>
            <div className="container">
                <h2 data-aos="fade-left" className="title">
                    <span>من نحنُ</span>
                    <p>من نحنُ</p>
                </h2>

                <div className="about-content">
                    {about.map((content) => {
                        return (
                            <div key={content.id} className="box">
                                <img data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom" className='image' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${content.img}`} alt="" />

                                <div data-aos="zoom-in" data-aos-duration="1500" className="text">
                                    {content.text}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* <div className="swiper_container">
                    <Swiper
                        spaceBetween={30}
                        initialSlide={3}
                        slidesPerView={4}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={false}
                        modules={[Autoplay]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="card-swiper">
                                <img src={swiper1} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-swiper">
                                <img src={swiper3} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-swiper">
                                <img src={swiper4} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-swiper">
                                <img src={swiper5} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-swiper">
                                <img src={swiper6} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-swiper">
                                <img src={swiper7} alt="" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div> */}
            </div>
        </section>
    )
}

export default About
