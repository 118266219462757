import React, { useEffect, useState } from 'react'
import landing from '../assest/landing.png'
import Aos from 'aos';
import "aos/dist/aos.css"

function Home () {
    const [home, setHome] = useState([]);

    useEffect(() => {
        Aos.init();

        // fetch home
        const fetchLogo = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?home`)
            const data = await res.json()
            return setHome(data);
        }
        fetchLogo()
    }, [])
    return (
        <section className='home' id='home'>
            <div className="blur1"></div>
            <div className="blur2"></div>
            <div className="container">
                <div className="social">
                    <ul>
                        <li><a href="tel:966500861924"><i className="fa fa-phone"></i></a></li>
                        <li><a target='_blank' href="https://api.whatsapp.com/send/?phone=966500861924"><i className="fa fa-whatsapp"></i></a></li>
                    </ul>
                </div>
                <div className="content">
                    <div data-aos="fade-left" data-aos-duration="3000" className="text-area">
                        <div className="text">
                            <h1> شركة <span>الفردوس</span> <br />  لخـدمات التنظيـف</h1>
                            <p>لاننا في شركة الفردوس تهمنا صحتك فحرصا منا وفرنا لك خدمات التنظيف المنزلية بأرخص أسعار عروض شركات التنظيف
                                كل ما عليك هو الاتصال علينا و طلب مندوب للمعاينة دون مغادرتك للمنزل</p>
                            <div className="btns">
                                <button class="button type1">
                                    <span class="btn-txt"><a href="tel:966500861924">إتصل بنا</a></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-right" data-aos-duration="3000" className="image-area">
                        <div className="image">
                            <img src={landing} alt="" />
                        </div>
                    </div>
                </div>

                <div className="reviews">
                    <div class="counter">
                        <ul>
                            <li>
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height="2rem" viewBox="0 0 640 512"><path d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z" /></svg></span>
                                        </div>
                                        <p>السرعة و الاتقان</p>
                                    </div>
                                </div>
                            </li>
                            <li >
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 288 512"><path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z" /></svg></span>
                                        </div>
                                        <p>اسعار تنافسية</p>
                                    </div>
                                </div>
                            </li>
                            <li >
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height="2rem" viewBox="0 0 512 512"><path d="M458.6 255.9l46-45c13.7-13 7.3-36-10.7-40.3l-62.7-16 17.7-62c5-17.8-11.8-34.7-29.7-29.7l-62 17.7-16-62.7C337.1 .2 313.8-6.3 301 7.2L256 53.6 211 7.2c-12.6-13.4-36-7.2-40.3 10.7l-16 62.7-62-17.7C74.9 57.9 58.1 74.7 63 92.6l17.7 62-62.7 16C.1 174.9-6.3 197.9 7.4 210.9l46 45-46 45c-13.7 13-7.3 36 10.7 40.3l62.7 16-17.7 62c-5 17.8 11.8 34.7 29.7 29.7l62-17.7 16 62.7c4.4 18.6 27.7 24 40.3 10.7L256 458.6l45 46c12.5 13.5 36 7.5 40.3-10.7l16-62.7 62 17.7c17.8 5 34.7-11.8 29.7-29.7l-17.7-62 62.7-16c18-4.3 24.4-27.4 10.7-40.3l-46-45z" /></svg></span>
                                        </div>
                                        <p>ضمان ما بعد الخدمة</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Home
