import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchProjects } from '../rtk/slices/projects-slice';

function Project_Page () {
    const total30 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { id } = useParams();

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };

    setTimeout(() => {
        const support = document.querySelector('.support-menu');
        const social = document.querySelector('.contact-menu .main');
        if (support) {
            support.addEventListener("click", () => {
                social.classList.toggle("active")
            })
        }
    }, 2000)

    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects.projects);
    const color = useSelector((state) => state.color.color);

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchProjects());

    }, [])

    // useEffect(() => {
    //     return () => {
    //         const support = document.querySelector('.support-menu');
    //         const social = document.querySelector('.contact-menu .main');
    //         if (support) {
    //             support.addEventListener("click", () => {
    //                 social.classList.toggle("active")
    //             })
    //         }
    //     }
    // }, [])

    return (
        <section className='projects service' id='projects'>
            <div className="contact-menu">
                <div className="main">
                    <div className="up">
                        <a href={"tel:966500861924"} className="card1">
                            <i className="fa fa-phone instagram"></i>
                        </a>

                    </div>
                    <div className="down">
                        <a href={"https://api.whatsapp.com/send/?phone=966500861924"} target='_blank' className="card3">
                            <i className="fa fa-whatsapp whatsapp"></i>
                        </a>
                    </div>
                </div>
                <button target='_blank' className="card4 support-menu">
                    <i className="fa fa-phone support"></i>
                </button>
            </div>


            <div className="container">
                <h2 className="title">
                    <span>المشاريع</span>
                    <p style={{ textTransform: "capitalize" }}>المشاريع</p>
                </h2>

                {projects.map((project) => {
                    return (
                        project['id'] == id ?
                            <div className="card-service">
                                <div className="content">
                                    <div className="box">
                                        <h3>مشروع : {project.name}؟</h3>
                                    </div>
                                    {project.video.length > 4 ?
                                        <div className="img">
                                            <video width={"100%"} controls src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${project.video}`} alt={project.name} />
                                        </div>
                                        : null}
                                    {
                                        total30.map((number) => {
                                            const imgKey = `img_project${number}`;
                                            const captionKey = `caption_img_project${number}`;

                                            if (project[imgKey] && project[imgKey].length > 4) {
                                                return (
                                                    <div key={number} className="testimonials-item" style={{ padding: "2rem" }}>
                                                        <img width={"100%"} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${project[imgKey]}`} alt="" />
                                                        <h1>{project[captionKey]}</h1>
                                                    </div>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                    <a href='tel:966500861924' style={{ textAlign: "center" }} class="btns"><button class="button type1"><span class="btn-txt"><a href="tel:966500861924">إتصل بنا</a></span></button></a>
                                </div>
                            </div>
                            : null
                    )
                })}


                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Project_Page
